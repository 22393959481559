// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

// vendor

import jQuery from "jquery";
import "slick-carousel";

// custom

jQuery(document).ready(function () {
    jQuery(".humburger-icon").on("click", function () {
        jQuery("body").toggleClass("expanded-icon");
    });

    jQuery(".header__inner nav#site-navigation ul li a").on("click", function () {
        jQuery("body").removeClass("expanded-icon");
    });

    jQuery("table").wrap("<div class='table-set-responsive'></div>");
});


if ( jQuery(".product__slider-main").length ) {
    jQuery(".product__slider-main").on("init", function () {
        jQuery(".product__slider-main").fadeIn(1000);
    }).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        lazyLoad: "ondemand",
        autoplaySpeed: 3000,
        asNavFor: ".product__slider-thmb"
    });

    jQuery(".product__slider-thmb").on("init", function () {
        jQuery(".product__slider-thmb").fadeIn(1000);
    }).slick({
        slidesToShow: 16,
        slidesToScroll: 1,
        lazyLoad: "ondemand",
        asNavFor: ".product__slider-main",
        dots: false,
        centerMode: false,
        focusOnSelect: true,
        loop:false
    });

    // remove active class from all thumbnail slides
    jQuery(".product__slider-thmb .slick-slide").removeClass("slick-active");

    // set active class to first thumbnail slides
    jQuery(".product__slider-thmb .slick-slide").eq(0).addClass("slick-active");

    // On before slide change match active thumbnail to current slide
    jQuery(".product__slider-main").on("beforeChange", function (nextSlide) {
        jQuery(".product__slider-thmb .slick-slide").removeClass("slick-active");
        jQuery(".product__slider-thmb .slick-slide").eq(nextSlide).addClass("slick-active");
    });
}
